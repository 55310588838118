export enum FlowType {
  Faconne = 'faconne',
  Trouve = 'trouve',
}

export enum Speciality {
  Courtage = 'courtage',
  ContractantGeneral = 'contractant_general',
}

export enum HubSpotStates {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Merged = 'MERGED',
}

export enum FormConfigTypes {
  Lead = 'lead',
  LeadQualif = 'lead_qualif',
}

export type TestingProps = {
  testId?: string;
};

/**
 * @description
 * 1. Do not create range > 1000
 * @example
 * NumericRange<1, 3> // 1 | 2 | 3
 */
export type NumericRange<
  START extends number,
  END extends number,
  ARR extends unknown[] = [],
  ACC extends number = never,
> = ARR['length'] extends END
  ? ACC | START | END
  : NumericRange<START, END, [...ARR, 1], ARR[START] extends undefined ? ACC : ACC | ARR['length']>;

export enum DocumentTypes {
  ConvictionAudit = 'conviction_audit',
  SearchMandate = 'search_mandate',
  DealAttachment = 'deal_attachment',
}
