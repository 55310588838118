'use client';

import { merge } from 'lodash';
import { useRouter, useSearchParams } from 'next/navigation';
import queryString from 'query-string';

export default function useRouterWithTracking() {
  const params = useSearchParams();
  const router = useRouter();

  const push = (url: string, queryObject?: Record<string, string | null>, removeFromQuery?: Array<string>) => {
    const trackingParams = queryString.parse(params.toString());

    if (removeFromQuery && removeFromQuery?.length > 0) {
      for (let i = 0; i < removeFromQuery.length; i += 1) {
        delete trackingParams[removeFromQuery[i]];
      }
    }

    const trackingParamsQuery = queryString.stringify(merge(trackingParams, queryObject));

    router.push(`${url}${trackingParamsQuery ? `?${trackingParamsQuery}` : ''}`);
  };

  return { push, refresh: router.refresh, back: router.back };
}
