import '@assets/styles/loader.css';
import classnames from 'classnames';

export default function Loading({ className, inverted, fill = true }:
{ className?: string, inverted?: boolean, fill?: boolean }) {
  return (
    <div
      data-testid="loader"
      className={classnames('flex items-center justify-center', className, {
        'loader_background_color--inverted': inverted,
        'flex-1 h-full': fill,
      })}
    >
      <div className="loader">
        {/* eslint-disable-next-line react/no-array-index-key */}
        {Array.from({ length: 8 }).map((_, index) => <div key={index} />)}
      </div>
    </div>
  );
}
