'use client';

import { Button, IconName } from '@little-worker/hestia';
import classnames from 'classnames';
import { useTranslations } from 'next-intl';

import Loading from '@/app/[locale]/loading';

interface FormNavigationButtonsGroupProps {
  isFirstStep?: boolean;
  isLastStep?: boolean;
  onPrevious?: () => void;
  onNext?: () => void;
  isNextDisabled?: boolean;
  nextButtonLabel?: string | undefined;
  nextButtonIcon?: `${IconName}`;
  isLoading?: boolean;
}

function FormNavigationButtonsGroup(props: FormNavigationButtonsGroupProps) {
  const t = useTranslations('Common.Navigation');

  const {
    isFirstStep = false,
    isLastStep = false,
    onPrevious,
    onNext,
    isNextDisabled = false,
    nextButtonLabel = isLastStep ? t('validate') : t('next'),
    nextButtonIcon = 'ArrowRight02',
    isLoading = false,
  } = props;

  return (
    <div className="grid grid-cols-4 gap-grid">
      {!isFirstStep && (
        <Button
          className="col-span-2"
          variant="text"
          iconName="ArrowLeft02"
          iconSide="left"
          isDisabled={isFirstStep}
          onPress={() => onPrevious && onPrevious()}
          aria-label={t('previous')}
          testId="navigation-buttons-group-previous"
        >
          {t('previous')}
        </Button>
      )}
      <Button
        className={classnames({
          'col-span-2': !isFirstStep,
          'col-span-4': isFirstStep,
        })}
        variant="filled"
        iconName={!isLoading ? nextButtonIcon : undefined}
        iconSide="right"
        isDisabled={isNextDisabled || isLoading}
        onPress={onNext}
        aria-label={nextButtonLabel}
        testId="navigation-buttons-group-next"
      >
        {isLoading ? <Loading className="h-200 scale-50" fill={false} /> : nextButtonLabel}
      </Button>
    </div>
  );
}

export default FormNavigationButtonsGroup;
