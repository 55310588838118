import { RefObject } from 'react';

const useAnimateCSS = (
  ref: RefObject<HTMLDivElement>,
  animation: string,
  prefix = 'animate__',
) => new Promise((resolve) => {
  const animationName = `${prefix}${animation}`;
  const container = document.getElementsByTagName('main')[0];

  if (!ref.current) {
    return;
  }
  container.style.overflow = 'hidden';
  ref.current.classList.add(`${prefix}animated`, animationName);

  function handleAnimationEnd(event: AnimationEvent) {
    event.stopPropagation();
    if (ref.current) {
      ref.current.classList.remove(`${prefix}animated`, animationName);
      resolve('Animation ended');
      container.style.removeProperty('overflow');
    }
  }

  ref.current.addEventListener('animationend', handleAnimationEnd, { once: true });
});

export default useAnimateCSS;
